import Layout from "layout";
import React, { useEffect, useState } from "react";
import {
  useGetBuyTransactions,
  useGetBuyTransactionsApproval,
  useGetDepositBank,
  useUpdateBuyTransaction,
  useUpdateDepositBank,
} from "utils/api/user.api";
import { type UserTransaction } from "utils/types/user.type";
import { Tabs } from "antd";
import { styled } from "styled-components";

export default function MytsionOrder() {
  const { data: completedTrans, isLoading } = useGetBuyTransactions("true");
  const { data: pendingTrans } = useGetBuyTransactionsApproval();
  const { data: depositBank } = useGetDepositBank();
  const updateDepositBank = useUpdateDepositBank();

  const [formValues, setFormValues] = useState({
    accountHolderName: "",
    accountNumber: "",
    bank: "",
    quote: "",
  });

  const [isModified, setIsModified] = useState(false);

  useEffect(() => {
    if (depositBank) {
      setFormValues({
        accountHolderName: depositBank.accountHolderName,
        accountNumber: depositBank.accountNumber,
        bank: depositBank.bank,
        quote: depositBank.quote,
      });
      setIsModified(false); // Reset modification state when initial values are set
    }
  }, [depositBank]);

  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
    // Check if any field has changed from initial values
    setIsModified(
      depositBank
        ? value !== depositBank[name as keyof typeof depositBank] ||
            (Object.keys(formValues) as Array<keyof typeof formValues>).some(
              (key) => formValues[key] !== depositBank[key]
            )
        : false
    );
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    updateDepositBank.mutate(formValues);
  };

  console.log("🚀 ~ MytsionOrder ~ completedTrans:", completedTrans);
  const CustomTabs = styled(Tabs)`
    .ant-tabs-tab:hover .ant-tabs-tab-btn {
      color: #f7a600 !important;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: black !important;
    }

    .ant-tabs-ink-bar {
      background-color: #f7a600 !important;
    }
    .ant-tabs-nav-list {
      padding-left: 24px !important;
    }
    .ant-tabs-tab-btn {
      color: #8f92a1;
    }
  `;
  const items = [
    {
      key: "1",
      label: "Pending Transactions",
      children: (
        <div className="dashboard-sidebar table-wrapper max-h-screen overflow-auto">
          <table
            className="w-full border border-[#F1F1F1] "
            style={{ borderCollapse: "collapse", borderSpacing: "0" }}
          >
            <thead className="bg-cyan-300 h-26 px-5 py-2 sticky top-[-1px] z-10">
              {" "}
              <tr>
                <th style={{ width: "20%" }} className="py-3 px-5 text-left">
                  Client
                </th>
                <th style={{ width: "20%" }} className="py-3 px-2 text-left">
                  Email
                </th>
                <th style={{ width: "20%" }} className="py-3 px-2 text-left">
                  Ordered Amount
                </th>
                <th style={{ width: "20%" }} className="py-3 px-2 text-left">
                  Order Date
                </th>
                <th style={{ width: "20%" }} className="py-3 px-2 text-left">
                  Status
                </th>
                <th style={{ width: "20%" }} className="py-3 px-2 text-left">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {pendingTrans?.map((item) => (
                <TableRow1 key={item?.id} row={item} />
              ))}
            </tbody>
          </table>
        </div>
      ),
    },
    {
      key: "2",
      label: "Completed Transactions",
      children: (
        <div className="dashboard-sidebar table-wrapper h-screen overflow-auto ">
          <table
            className="w-full border border-[#F1F1F1] "
            style={{ borderCollapse: "collapse", borderSpacing: "0" }}
          >
            <thead className="bg-cyan-300 h-26 px-5 py-2 sticky top-[-1px] z-10">
              {" "}
              <tr>
                <th style={{ width: "20%" }} className="py-3 px-5 text-left">
                  Client
                </th>
                <th style={{ width: "20%" }} className="py-3 px-2 text-left">
                  Email
                </th>
                <th style={{ width: "20%" }} className="py-3 px-2 text-left">
                  Ordered Amount
                </th>
                <th style={{ width: "20%" }} className="py-3 px-2 text-left">
                  Order Date
                </th>
                <th style={{ width: "20%" }} className="py-3 px-2 text-left">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {completedTrans?.map((transaction) => (
                <TableRow key={transaction?.id} transaction={transaction} />
              ))}
              {!isLoading &&
                completedTrans?.filter((item) => item.user?.email)?.length ===
                  0 && (
                  <tr>
                    <td>No Results Found</td>
                  </tr>
                )}
            </tbody>
          </table>
        </div>
      ),
    },
  ];
  return (
    <Layout>
      <div style={{ backgroundColor: " #FAFAFA" }} className="dashboard-main">
        <div>
          <div className=" relative h-40 flex items-center bg-cyan-800 rounded-lg min-w-[300px]">
            <text
              style={{
                fontWeight: "500",
                fontSize: "32px",
                color: "white",
                padding: "24px",
              }}
            >
              Mystion Orders
            </text>
          </div>
          <div className="w-full flex justify-start items-start  p-6 mt-6 bg-white rounded-lg shadow-md">
            <div className="   w-[70%]">
              <h2 className="text-lg font-bold mb-4">Bank Account Detail</h2>
              <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-2 gap-4">
                  {/* Account Holder Input */}
                  <div>
                    <label className="block text-sm whitespace-nowrap text-gray-400">
                      Account Holder
                    </label>
                    <input
                      type="text"
                      name="accountHolderName"
                      value={formValues.accountHolderName}
                      onChange={handleInputChange}
                      className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-cyan-800 focus:border-cyan-800"
                    />
                  </div>

                  {/* Account Number Input */}
                  <div>
                    <label className="block text-sm whitespace-nowrap text-gray-400">
                      Account Number
                    </label>
                    <input
                      type="text"
                      name="accountNumber"
                      value={formValues.accountNumber}
                      onChange={handleInputChange}
                      className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-cyan-800 focus:border-cyan-800"
                    />
                  </div>

                  {/* Bank Input */}
                  <div>
                    <label className="block text-sm whitespace-nowrap text-gray-400">
                      Bank
                    </label>
                    <input
                      type="text"
                      name="bank"
                      value={formValues.bank}
                      onChange={handleInputChange}
                      className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-cyan-800 focus:border-cyan-800"
                    />
                  </div>

                  {/* Quote Input */}
                  {/* <div>
                    <label className="block text-sm whitespace-nowrap text-gray-400">
                      Quote
                    </label>
                    <input
                      type="text"
                      name="quote"
                      value={formValues.quote}
                      onChange={handleInputChange}
                      className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-cyan-800 focus:border-cyan-800"
                    />
                  </div> */}

                  <button
                    type="submit"
                    disabled={!isModified || updateDepositBank.isLoading}
                    className={`w-full mt-6 py-2 rounded-md  ${
                      isModified
                        ? "bg-[#F7A600] text-white cursor-pointer"
                        : "bg-gray-300 text-gray-500 cursor-not-allowed"
                    }`}
                  >
                    {updateDepositBank.isLoading ? "Updating..." : "UPDATE"}
                  </button>
                </div>
              </form>
              {/* Update Button */}
            </div>
          </div>

          <div className="flex flex-col  ">
            {/* <div className="flex flex-col md:flex-row gap-4 justify-end pb-8">
              <div className="relative w-full lg:w-96">
                <input
                  type="text"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  placeholder="Search by date, email address or transaction type"
                  className="w-full text-grey-700 text-sm rounded-lg border border-grey-600 focus:outline-0 py-3.5 px-5 pr-10"
                />
                <img
                  src={searchIconSrc}
                  alt="Search"
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 text-grey-700 w-5 h-5"
                />
              </div>
              <button className="bg-cyan-300 border border-cyan-800 rounded-lg text-cyan-800 text-[18px] font-[500] transition px-8 lg:px-16 py-3">
                {completedTrans && (
                  <CSVLink
                    data={completedTrans}
                    style={{ textDecoration: "none", color: "#F7A600" }}
                    filename="pendingTransaction.csv"
                  >
                    EXPORT
                  </CSVLink>
                )}
              </button>
            </div> */}
            <CustomTabs
              defaultActiveKey="1"
              items={items}
              style={{
                width: "100%",
              }}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}

function TableRow({ transaction }: { transaction: UserTransaction }) {
  console.log("🚀 ~ TableRow ~ transaction:", transaction);
  const [isEdit, setIsEdit] = useState(false);

  // Determine the row and text color based on the transaction type
  const getRowColor = (type: string) => {
    switch (type) {
      case "Withdraw Crypto":
        return "bg-[#E7FFF2]";
      case "Deposit Crypto":
        return "bg-[#FFE3E3]";
      case "Swap Crypto To Crypto":
        return "bg-[#F1F1F1]";
      case "Swap Fiat To Crypto":
        return "bg-[#F1F1F1]";
      case "Swap Crypto To Fiat":
        return "bg-[#F1F1F1]";
      default:
        return "bg-[#ffffff]";
    }
  };

  const renderDetails = () => {
    return (
      <>
        <td className="flex-1">
          <strong className="text-xs">Ammount paid:</strong>
          <p className="text-[#8F92A1] text-xs">${transaction?.amount}</p>
        </td>
        <td className="flex-1">
          <strong className="text-xs">Receipt Proof:</strong>
          <div className="text-black-900 h-24  text-xs">
            <img
              style={{ height: "100px", width: "100px" }}
              src={transaction?.proofOfPayment}
              alt=""
              className="rounded-2xl"
            />
          </div>
        </td>
        <td className="flex-1">
          <strong className="text-xs">Status:</strong>
          <p
            className={`text-xs font-semibold ${
              transaction?.status === "Completed"
                ? "text-green-500"
                : "text-red-500"
            }`}
          >
            {transaction?.status}
          </p>
        </td>
      </>
    );
  };

  // Determine the button class based on the isEdit state
  const buttonClass = isEdit
    ? "text-cyan-800 bg-cyan-300 border-cyan-800"
    : "text-gray-800  bg-[#F1F1F1]  border-[#8F92A1]";

  return (
    <>
      <tr className={`${getRowColor(transaction?.type)}  transition`}>
        <td className="py-5 border-b border-grey-800/30  px-5">
          <p className="text-[#8F92A1] text-xs ">
            {transaction?.user?.fullname}
          </p>
        </td>
        <td className="py-5 border-b border-grey-800/30  px-2">
          <p className="text-[#8F92A1] text-xs ">{transaction?.user?.email}</p>
        </td>

        <td className="py-5 border-b border-grey-800/30  px-2">
          <p className=" text-sm text-[#8F92A1] flex gap-1">
            {transaction?.type.includes("Withdraw Crypto") &&
              transaction?.currency?.symbol}
            {/* <img
              src={transaction?.coin?.icon || XAFIcon}
              className="w-5"
              alt="icon"
            />{" "} */}
            {transaction?.swappedAmount.toFixed(3)} MSTN
          </p>
        </td>
        <td className="px-2 border-r-0 py-5 border-b border-grey-800/30 ">
          <p className="text-[#8F92A1] text-xs ">
            {new Date(transaction?.createdAt)
              .toLocaleString("en-GB", {
                day: "2-digit",
                month: "long",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })
              .replace(" at", ",")}
          </p>
        </td>
        <td className="px-2 border-l-0 py-5 border-b border-grey-800/30 ">
          <button
            onClick={() => setIsEdit(!isEdit)}
            type="button"
            className={`text-xs font-medium  rounded-lg px-8 py-2.5 border whitespace-nowrap ${buttonClass}`}
          >
            {isEdit ? "COLLAPSE" : "VIEW DETAIL"}
          </button>
        </td>
      </tr>
      {isEdit && (
        <tr className="relative w-full">
          <td colSpan={6} className="p-0 border-t-0 w-full">
            <table className="px-6 pb-6 w-full">
              <tbody className="w-full">
                <tr className="flex">{renderDetails()}</tr>
              </tbody>
            </table>
          </td>
        </tr>
      )}
    </>
  );
}
function TableRow1({ row }: { row: UserTransaction }) {
  const [isReview, setReview] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { mutate: updateTransaction, isLoading } = useUpdateBuyTransaction();

  // Determine the button class based on the isEdit state
  const buttonClass = isEdit
    ? "text-cyan-800 bg-cyan-300 border-cyan-800"
    : "text-gray-800 bg-[#F1F1F1]  border-[#8F92A1]";

  // Toggle detail view and edit mode
  const toggleDetail = () => {
    setReview(!isReview);
    setIsEdit(!isEdit);
  };

  return (
    <>
      <tr className={isReview ? "" : "bg-white"}>
        <td className="border-b border-grey-800/30 py-5 px-5">
          <p className="text-[#8F92A1] text-sm ">{row?.user?.fullname}</p>
        </td>
        <td className="border-b border-grey-800/30 py-5 px-2">
          <p className="text-[#8F92A1] text-sm ">{row?.user?.email}</p>
        </td>
        <td className="border-b border-grey-800/30 py-5 px-2">
          <p className=" text-sm text-[#8F92A1] flex gap-1">
            {row?.type.includes("Withdraw Crypto") && row?.currency?.symbol}
            {/* <img
              src={row?.coin?.icon || XAFIcon}
              className="w-5"
              alt="icon"
            />{" "} */}
            {row?.swappedAmount.toFixed(3)} MSTN
          </p>
        </td>
        <td className="border-b border-grey-800/30 rounded-l-lg py-5 px-2">
          <p className="text-[#8F92A1] text-sm ">
            {" "}
            {new Date(row?.createdAt)
              .toLocaleString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })
              .replace(/\//g, "-")}
          </p>
        </td>

        {/* <td className="border-b border-grey-800/30 py-5  text-left px-5">
          <p className="text-[#8F92A1] text-sm ">{row?.type}</p>
        </td> */}
        <td className="border-b border-grey-800/30 py-5  text-left px-2">
          <p className="text-[#8F92A1] text-sm ">{row?.status}</p>
        </td>
        <td className="border-b border-grey-800/30 py-5 px-2">
          <button
            onClick={() => {
              toggleDetail();
            }}
            type="button"
            className={`text-xs font-semibold rounded-lg px-8 py-2.5 border whitespace-nowrap ${buttonClass}`}
          >
            {isEdit ? "COLLAPSE" : "VIEW DETAIL"}
          </button>
        </td>
      </tr>
      {isReview && (
        <tr className="relative w-full">
          <td colSpan={6} className="p-0 border-t-0 w-full">
            <table className="px-6 pb-6 w-full">
              <tbody className="w-full">
                <tr className="flex ">
                  <td className="flex-1">
                    <strong className="text-xs">Ammount paid:</strong>
                    <p className="text-[#8F92A1] text-xs">${row?.amount}</p>
                  </td>
                  <td className="flex-1">
                    <strong className="text-xs">Receipt Proof:</strong>
                    <div className="text-black-900 h-24  text-xs">
                      <img
                        style={{ height: "100px", width: "100px" }}
                        src={row?.proofOfPayment}
                        alt=""
                        className="rounded-2xl"
                        // ref={row?.proofOfPayment}
                      />
                    </div>
                  </td>

                  <td className="flex-1">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "10px",
                      }}
                    >
                      <button
                        type="button"
                        onClick={() =>
                          updateTransaction({
                            transactionId: row?.id,
                            status: "Rejected",
                          })
                        }
                        className="text-black text-xs bg-gray-200 font-medium rounded-lg px-8 py-2.5"
                        disabled={isLoading}
                      >
                        {/* {isLoading ? ( */}
                        {/* <div className="w-5 h-5 rounded-full animate-spin mx-auto border-2 border-solid border-cyan-800 border-t-transparent"></div> */}
                        {/* ) : ( */}
                        Reject
                        {/* )} */}
                      </button>
                      <button
                        onClick={() =>
                          updateTransaction({
                            transactionId: row?.id,
                            status: "Completed",
                          })
                        }
                        type="button"
                        className="bg-cyan-800 hover:text-cyan-800 hover:bg-cyan-300 text-white text-xs font-medium border border-cyan-800 rounded-lg px-8 py-2.5 transition"
                        disabled={isLoading}
                      >
                        {/* {isLoading ? ( */}
                        {/* <div className="w-5 h-5 rounded-full animate-spin mx-auto border-2 border-solid border-white border-t-transparent"></div> */}
                        {/* ) : ( */}
                        Approve
                        {/* )} */}
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      )}
    </>
  );
}
